<html>

<head>
  <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
</head>

<body>
  <div class="container">
    <div class="row appointment_block">
      <div class="col-md-8 offset-md-2">
        <form [formGroup]="serviceForm">

        </form>
      </div>

    </div>
  </div>
</body>

</html>
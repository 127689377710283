import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-health-service',
  templateUrl: './health-service.component.html',
  styleUrls: ['./health-service.component.scss']
})
export class HealthServiceComponent implements OnInit {
  public serviceForm: FormGroup;

  constructor() { }

  ngOnInit(): void {
  }

}

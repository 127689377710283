<div class="container">
    <h2 class="text-center">{{languageLabels._resetYourPassword }}</h2>
    <div class="row">
        <div class="forgot-pwd  col-sm-4  offset-sm-4">

            <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">

                <div class="form-group">
                    <label for="password">{{languageLabels._newPassword}}</label>
                    <input [type]="show ? 'text' : 'password'" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                    <!-- <small id="password" class="form-text text-muted"> ( Minimum 4 characters with 1 special character <br> 1 number & 1 uppercase letter.)</small> -->
                    <span (click)="password()" *ngIf="show" id="show_password"> <clr-icon shape="eye"></clr-icon></span>
                    <span (click)="password()" *ngIf="!show" id="show_password"> <clr-icon shape="eye-hide"></clr-icon></span>
                    <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                        <div *ngIf="f.password.errors.required">Password is required</div>
                        <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div>

                    </div>
                </div>
                <div class="form-group">
                    <label for="confirmpassword">{{languageLabels._confirmPassword}}</label>
                    <input [type]="show ? 'text' : 'password'" formControlName="confirmpassword" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmpassword.errors }" />
                    <div *ngIf="submitted && f.confirmpassword.errors" class="invalid-feedback">
                        <div *ngIf="f.confirmpassword.errors.required">Confirm Password is required</div>
                        <div *ngIf="f.confirmpassword.errors.mustMatch">Passwords must match</div>
                    </div>

                </div>
                <!-- <p class="p_forgot">New user ?<span style="color:hsl(198, 100%, 34%);" router> Please Signup</span></p> -->
                <div class="form-group">
                    <div class="row">
                        <div class=" col-md-12 d-flex justify-content-start">
                            <button class=" submit-btn">{{languageLabels._updatePassword}}</button>
                        </div>
                    </div>

                </div>
            </form>
        </div>
    </div>
</div>
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HTTPProviderService } from '../http-provider/http-provider.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class RequestedHospitalService {

    constructor(private http: HTTPProviderService,
        // private changeDetector: ChangeDetectorRef,
        private router: Router) {
    }

    private _listners = new Subject<any>();
    listen(): Observable<any> {
        return this._listners.asObservable();
    }


    public hospital = new BehaviorSubject<any>(null);
    public requestedDoctorHos = new BehaviorSubject<any>(null);

    public requestOfflineHospital = new BehaviorSubject<any>(null);

    setHospital(data: Object) {
        this.hospital.next(data);
    }

    getHospital(): Observable<any> {
        return this.hospital.asObservable();
    }


    sendRequestHospitalToDoctor(data: Object) {
        this.requestedDoctorHos.next(data);
    }

    getDoctorRequestHospital(): Observable<any> {
        return this.requestedDoctorHos.asObservable();
    }

    setOfflineHospital(data: Object) {
        this.requestOfflineHospital.next(data);
    }
    
    getOffLineHospital(): Observable<any> {
        return this.requestOfflineHospital.asObservable();
    }

}

export class PatientLanguageSelectionRequest {
    languageID: string;
}

export class DoctorListRequestBasedOnHospital {
    hospitalID : number;
    fromDate: string;
    toDate: string;
}


export class PatientProfile {
    patientID: number;
    firstName: string;
    lastName: string;
    gender: string;
    dateOfBirth: string;
    emailID: string;
    phoneNumber: string;
    country: string;
    languageID: string;
}

export class LanguageResponse {
    languageID: string;
    languageName: string;
}
export class PatientLanguageUpdateRequest {
    patientID: number;
    languageID: string;
}

export class EmailServiceMessage {
    from: string;
    to: string;
    subject: string;
    body: string;
}


export class ResetPassword {
    emailID:String;
}

export class phoneNumberLogin
{
  phoneNumber:string;

}
export class DoctorSerachDetials {
    doctorID: string;
    doctorName: string;
    doctorQualification: string;
    doctorDealsWith: string;
    dcotorExperience: string;
    doctorImage: string;
    about: string;
}
export class DcotorsDepartmentListResponse {
    departmentID: number;
    departmentName: string;
}

import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})

export class HTTPProviderService {
  post(arg0: string, formData: FormData) {
    throw new Error('Method not implemented.');
  }
  private url: string;

  private _refreshNeeded$ = new Subject<void>();
    get: any;

  //  private url: string  =  window["cfgApiBaseUrl"];
  constructor(public http: HttpClient) {
this.url = window['cfgApiBaseUrl'];
    // this.url = 'https://www.dehpa.com/dehpa_be/';
    // this.url = 'http://tomcat19.hostingraja.org:33023/dehpa_be/';
    // this.url = 'http://localhost:8080/';
  }

  get refreshNeeded$() {
    // this.url = 'http://localhost:8080/';
    return this._refreshNeeded$;
  }

  processServerRequest(
    api: string,
    restType: string,
    data?: any
  ): Observable<any> {
    var finalUrl = this.url + api;    
    const headers = new HttpHeaders().set(
      'Content-Type',
      'application/json; charset=utf-8'
    );
    switch (restType) {
      case 'get':
        return this.http
          .get(finalUrl + this.serialize(data), { headers })
          .pipe(map(this.extractData.bind(this)))
          .pipe(catchError(this.handleError));
      case 'post':
        return this.http
          .post(finalUrl, data, { headers })
          .pipe(map(this.extractData.bind(this)))
          .pipe(
            tap(() => {
              this._refreshNeeded$.next();
            })
          )
          .pipe(catchError(this.handleError));
    }
  }

  private handleError(error: HttpErrorResponse) {
    var message;
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
      message = error.error.message;
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` + `body was: ${error.error}`
      );
      message = error;
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened; please try again later.');
  }

  private extractData(res: any) {
    return res || {};
  }

  /**
   *  function to serialize plain object to get parameters
   * @param params strings that needs to be appended to the url
   */
  private serialize(params: any): string {
    if (params === undefined) {
      return '';
    }
    return (
      '?' +
      Object.keys(params)
        .reduce((a, k) => {
          a.push(k + '=' + encodeURIComponent(params[k]));
          return a;
        }, [])
        .join('&')
    );
  }

  private unauthorized() {
    return throwError({ status: 401, error: { message: 'Unauthorised' } });
  }
}

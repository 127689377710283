import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { ClrForm } from '@clr/angular';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { HTTPProviderService } from 'src/app/core/http-provider/http-provider.service';
import { OldPwdValidators, UpdateUserPasswordRequest } from './forgot-password.model';
import { MustMatch } from 'src/app/users/core-ui/helpers/must-match.validator';
import { LanguageLabelService } from 'src/app/core/services/languageLabels.service';
import { RequestedHospitalService } from 'src/app/core/services/requestedHospital.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
 public forgoPwdform:FormGroup;
 public datauser:{};
 public cookie:string;
 public emailId:string;
 public newPassword:string;
 public oldPassword:string;
 public confirmPassword:string;
 public resetModal:boolean=false;
 public helpertxt:boolean;
 public error:boolean=false;
 public patienID : any;
 public submitted = false;
 public registerForm: FormGroup;
  public show: boolean;
  public currentHospital: any = {};
  public languageLabels: any = {};


 constructor(private formBuilder: FormBuilder,
  private router: Router, private route: ActivatedRoute,
  private http: HTTPProviderService,
  private labelService: LanguageLabelService,
  private getHospital: RequestedHospitalService
  )  {
   this.datauser = JSON.parse(localStorage.getItem('DehpaUser'));
    // this.languageLabels = JSON.parse(localStorage.getItem('labels'));
    // this.currentHospital = JSON.parse(localStorage.getItem("CurrentHospital"));
    this.labelService.getLabels().subscribe(message => {
      if (message) {
        this.languageLabels = message;
      }
    });

    this.getHospital.getHospital().subscribe(data=>{
      if(data) {
        this.currentHospital = data;
      }
    })

    }
ngOnInit() {

  this.patienID = this.route.snapshot.paramMap.get('patientID');
  this.registerForm = this.formBuilder.group({
    // ,Validators.pattern("^(?=.[a-z])(?=.[A-Z])(?=.[0-9])(?=.[$@$!%?&])[A-Za-z\d$@$!%?&].{8,}$")
    password: ['', [Validators.required, Validators.minLength(6)]],
    confirmpassword: ['', Validators.required],
  }, {
    validator: MustMatch('password', 'confirmpassword')
  });

  }

  get f() {
    return this.registerForm.controls;
  }
  password() {
    this.show = !this.show;
}

  public onSubmit() {

    this.submitted = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    const request = new UpdateUserPasswordRequest();
    request.patientID = this.patienID;
    request.password = this.registerForm.value.confirmpassword;
    request.hospitalID = this.currentHospital.hospitalID;
    
    this.http.processServerRequest('account/userPasswordUpdate', 'post',request).subscribe((data) => {
      if(data.status) {
        alert("upadated succesfully.......!!");
        this.router.navigate(['/hospital/'+this.currentHospital.hospitalName]);
      }
      else {
        alert('Password didnt Matched.... try once Again');
      }
    });
  }
   

    public logout(): void {
  
    localStorage.removeItem('DUser');
    this.router.navigate(['/hospital/'+this.currentHospital.hospitalName]);
   
  }

}

import { Injectable, ChangeDetectorRef } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { HTTPProviderService } from '../http-provider/http-provider.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Title, Meta } from '@angular/platform-browser';
import { PatientLanguageSelectionRequest } from 'src/app/users/core-ui/header/header.model';
import { Observable, Subject } from 'rxjs';
import { LanguageLabelService } from './languageLabels.service';
import { DepartmentsRequest } from 'src/app/users/main-hospital/dashboard/home/home.model';

@Injectable()
export class MyAppLoadService {
  public lisOfHospital = [];
  public languageLabels: any = {};
  public selectedLanguage: any;

  constructor(
    private http: HTTPProviderService,
    private router: Router,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private lables: LanguageLabelService
  ) {}

  intializeLabels() {
    const requests = new PatientLanguageSelectionRequest();
    requests.languageID = 'en';
    this.http
      .processServerRequest('dashboard/patientLabels', 'post', requests)
      .subscribe((data) => {
        this.languageLabels = data;
        this.lables.sendLabels(this.languageLabels);
      });
  }

  intiallizeLanguage() {
    this.http
      .processServerRequest('dashboard/languages', 'post')
      .subscribe((data) => {
        this.lables.setLanguages(data);
      });
  }

  intializeDepartments() {
    let lang = this.selectedLanguage || 'en';
    const request = new DepartmentsRequest();
    request.languageID = lang;
    this.http
      .processServerRequest(
        'doctorDashboard/departmentDescriptions',
        'post',
        request
      )
      .subscribe((data) => {
        this.lables.setDepartments(data.metaData);
      });
  }

  initializeMyApp() {
    this.getHospitals();
  }
  public getHospitals() {
    this.http
      .processServerRequest('hospital/listOfHospitals', 'post')
      .subscribe((data) => {
        this.lisOfHospital = data.hospitalsList;
      });
  }
}

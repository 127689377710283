<!-- <div *ngIf="this.loader.getLoading()" class="cssload-container"> -->
<!-- <div *ngIf="true" class="cssload-container-1"> -->
<!-- <div class="cssload-speeding-wheel">
            
        </div> -->

<!-- <div class="ring-1">Loading
            <span class="spa"></span>
          </div>

    </div> -->

<!-- <div *ngIf="this.loader.getLoading()" class="cssload-container"> -->

<body>
    <div *ngIf="this.loader.getLoading()" class="cssload-container">
        <div align="center" class="fond">
            <div class="contener_general">
                <div class="contener_mixte">
                    <div class="ballcolor ball_1">&nbsp;</div>
                </div>
                <div class="contener_mixte">
                    <div class="ballcolor ball_2">&nbsp;</div>
                </div>
                <div class="contener_mixte">
                    <div class="ballcolor ball_3">&nbsp;</div>
                </div>
                <div class="contener_mixte">
                    <div class="ballcolor ball_4">&nbsp;</div>
                </div>
            </div>
            <div style="padding-top:15px;">
                <div style=" color: black;font-weight: 600;font-size: 18px;animation: blink 2s linear infinite;">Please
                    Wait While We Process Your Data.
                </div>
            </div>
        </div>
    </div>
</body>
import { BrowserModule, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ForgotPasswordComponent } from './core/shared/components/forgot-password/forgot-password.component';
import { CommonModule } from '@angular/common';
import { HospitalsModule } from './hospitals/hospitals.module';
// import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MyAppLoadService } from './core/services/myapp-load.service';
import { ClarityModule } from '@clr/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { SpinnerComponent } from './core/spinner/spinner/spinner.component';
// import { MatDatepickerModule } from '@angular/material/datepicker';
// import { MatInputModule } from '@angular/material/input';
// import { MatNativeDateModule } from '@angular/material/core';
import { LoadingInterceptor } from './core/spinner/loading.interceptor';
import { HealthServiceComponent } from './hospitals/offline/health-service/health-service.component';



export function init_appDepartments(firstLoadService: MyAppLoadService) {
  return () => firstLoadService.intializeDepartments();
}

@NgModule({
  declarations: [AppComponent, ForgotPasswordComponent, SpinnerComponent, HealthServiceComponent],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    ClarityModule,
    NgSelectModule,

    // FullCalendarModule,
    NgxDaterangepickerMd.forRoot(),

    // MatDatepickerModule,
    // MatInputModule,
    // MatNativeDateModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    Title,
    MyAppLoadService,

    {
      provide: APP_INITIALIZER,
      useFactory: init_appDepartments,
      deps: [MyAppLoadService],
      multi: true,
    },

    {
      provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true
    }

  ],

  bootstrap: [AppComponent],
})
export class AppModule {}

export class DoctorIdRequest {
  doctorID: string;
  hospitalID: number;
}

export class SubscriberRequest {
  emailID : string;
  subscribeDateAndTime: string;
  hospitalID: number;
}
export class  SpecificDoctorsRequest {
  languageID: string;
  hospitalID: number;
  doctorID: string;
}

export class FeaturesRequest {
  hospitalID: number;
}

export class DepartmentsRequest {
  languageID: string;
}
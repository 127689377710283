import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Title, Meta } from '@angular/platform-browser';
// import { environment } from './../environments/environment';
// import { FeaturesRequest } from './hospitals/offline/back-appointment-booking/back-appointment-booking.model';
import { RequestedHospitalService } from './core/services/requestedHospital.service';
import { HTTPProviderService } from './core/http-provider/http-provider.service';
declare var device;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public languageLabels: any;
  constructor(
    private spinner: NgxSpinnerService,
    private router: Router,
    private title: Title,
    private meta: Meta,
  ) {
    this.languageLabels = JSON.parse(localStorage.getItem('labels'));
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(10, 10);
    });

    this.spinner.show();
    setTimeout(() => {
      this.spinner.hide();
    }, 700);
    document.addEventListener(
      'deviceready',
      function () {
        alert(device.platform);
      },
      false
    );
  }

}

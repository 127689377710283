import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { HTTPProviderService } from '../http-provider/http-provider.service';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class LanguageLabelService {

    constructor(private http: HTTPProviderService,
        // private changeDetector: ChangeDetectorRef,
        private router: Router) {
    }

    private _listners = new Subject<any>();
    listen(): Observable<any> {
        return this._listners.asObservable();
    }


    private subject = new Subject<any>();
    private msg = new Subject<any>();
    private selectedLang = new Subject<any>();
    private languages = new BehaviorSubject<any>(null);
    private departments = new BehaviorSubject<any>(null);

    sendLabels(message: Object) {
        this.subject.next(message);
    }


    getLabels(): Observable<any> {
        return this.subject.asObservable();
    }

    setLanguage(lang: string) {
        this.selectedLang.next(lang);
    }

    getLangauge(): Observable<any> {
        return this.selectedLang.asObservable();
    }

    setmsg(message: Object) {
        this.msg.next(message);
    }

    getMsg(): Observable<any> {
        return this.msg.asObservable();
    }


    setLanguages(lang: Object) {
        this.languages.next(lang);
    }

    getLangauges(): Observable<any> {
        return this.languages.asObservable();
    }

    setDepartments(depart: Object) {
        this.departments.next(depart);
    }

    getDepartments(): Observable<any> {
        return this.departments.asObservable();
    }





}

import { NgModule, OnInit } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/hospital/Dehpa',
    pathMatch: 'full',
    // data: { preload: true },
  },
  {
    path: 'hospital/:id/doctor',
    loadChildren: () =>
      import('./doctors/doctors.module').then((m) => m.DoctorsModule),
    // data: { preload: true }
  },
  {
    path: 'hospital/:hospitalId',
    loadChildren: () =>
      import('./users/users.module').then((m) => m.UsersModule),
    // data: { preload: true }
  },
  {
    path: 'labs/:labSearchTerm',
    loadChildren: () => import('./labs/labs.module').then((m) => m.LabsModule),
  },
  {
    path: 'health',
    loadChildren: () =>
      import('./health-parameter/health-parameter.module').then(
        (m) => m.HealthParameterModule
      ),
    // data: { preload: true }
  },
  {
    path: 'hospitals/:hosName',
    loadChildren: () =>
      import('./hospitals/hospitals.module').then((m) => m.HospitalsModule),
  },

  {
    path: 'resources',
    loadChildren: () =>
      import('./resources/resources.module').then((m) => m.ResourcesModule),
  },

  // {
  //   path: 'labs/:labID',
  //   loadChildren: () => import('./labs/labs.module').then((m) => m.LabsModule),
  // },


  {
    path: 'drSamal',
    loadChildren: () =>
      import('./client-resources/client-resources.module').then(
        (m) => m.ClientResourcesModule
      ),
  },
  {
    path: 'admin-dehpa',
    loadChildren: () =>
      import('./dehpa-admin/dehpa-admin.module').then(
        (m) => m.DehpaAdminModule
      ),
  },
];

// useHash:true,

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabled',
    }),
  ],

  exports: [RouterModule],
})
export class AppRoutingModule {}
